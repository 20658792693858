import React, { useCallback, useContext } from 'react'
import Firebase from 'gatsby-plugin-firebase'
import { withRouter } from 'react-router';
import { Link } from 'gatsby'
import { AuthProvider, AuthContext } from '../components/auth/auth'
import Layout from '../components/layout'

const LogIn = () => {

  var actionCodeSettings = {
    url: 'https://sidechat.live/',
    handleCodeInApp: true
  }

  const handleSignIn = useCallback(async event => {
    event.preventDefault();
    const { email } = event.target.elements;
    console.log(email.value)
    const query = Firebase.firestore().collection('users').where('email', '==', email.value)

    query.get().then(function(querySnapshot) {
      if (querySnapshot.empty) {
        console.log('no doc with that name')
        Firebase.firestore().collection('users').add({
          email: email.value,
          upvotes: [],
          follows: []
        })
      } else {
        console.log('doc already exists')
      }
    })
    try {
      await
        Firebase.auth().sendSignInLinkToEmail(email.value, actionCodeSettings)
        .then(function() {
          alert("Check your email! We've sent you a magic link to sign in.")
          window.localStorage.setItem('emailForSignIn', email.value)
          window.location.href='/'
        })
    } catch (error) {
      alert(error);
    }
  })
  
  return (
    <AuthProvider>
      <Layout>
      <div className='auth-card'>

          <div className='auth-text'>
            <h1>Sign in</h1>
          </div>

          <form onSubmit={handleSignIn}>
            
            <input name='email' type='email' placeholder='Email' />

            <button type='submit'>Send Email Link</button>

            <p>Don't have an account? <Link to='/sign-up'>Sign up</Link></p>

          </form>

        </div>
      </Layout>
    </AuthProvider>
  )
}

export default LogIn