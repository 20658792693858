import React, { useContext } from 'react'
import Firebase from 'gatsby-plugin-firebase'
import { AuthProvider, AuthContext } from './auth/auth'
import { Link } from 'gatsby'
import '../css/layout.css'

const Layout = ({children}) => {
  const {currentUser} = useContext(AuthContext)
  return(
    <AuthProvider>
      <div className='layout'>
        <div className='navbar'>
          <Link to='/' className='site-name'>
            <h3>Coworker</h3>
          </Link>
          { currentUser ?
              <Link to='/profile' className='link-to-profile'>
                <h3>{currentUser.email}</h3>
              </Link>
            :
              <Link to='/sign-in' className='link-to-sign-up'>
                <h3>Join</h3>
              </Link>
          }
        </div>
        {children}
      </div>
    </AuthProvider>
  )
}

export default Layout